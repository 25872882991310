<template>
    <multi-select
        v-model="internalValue"
        :options="options"
        :multiple="false"
        trackBy="id"
        label="comment"
        placeholder="Выберите причину"
        select-label=""
        selected-label=""
        deselect-label=""
        deselected-label=""
        @input="onChange"
        :max-height="400"
        @search-change="onSearch"
        :show-no-results="false"
        :disabled="disabled"
    ></multi-select>
</template>

<script>
    import MultiSelect from 'vue-multiselect';
    import { QUALITY_CONTROL_ACT_REASONS_ENDPOINT } from '@utils/endpoints';

    export default {
        name: "QualityControlActReasonInput",

        components: { MultiSelect },

        props: {
            value: {},
            disabled: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                internalValue: this.value,
                reasons: [],
                options: [],
                request: null,
            }
        },

        watch: {
            value() {
                this.internalValue = this.value;
            }
        },

        methods: {
            onChange() {
                this.$emit( 'input', this.internalValue );
            },

            onSearch( search ) {
                this.options = this.reasons.filter(reason => reason.comment.includes(search))
            },

            load() {
                this.$http.get(QUALITY_CONTROL_ACT_REASONS_ENDPOINT, {
                      params: {
                          limit: 1000
                      }
                    })
                    .then(response => {
                        this.reasons = response.data.data.data
                        this.options = response.data.data.data
                    }).catch(response => this.$root.responseError(response, 'Ошибка загрузки причин акта контроля качества'))
            }
        },

        mounted() {
            this.load();
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

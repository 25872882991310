<template>
    <b-card no-body class="mt-2" style="background: rgb(245, 222, 179)">
        <b-card-body style="padding: .5rem">
            <h6 v-b-toggle.find-nomenclature style="margin-top: .5rem;font-weight: 600;cursor:pointer;">
                <span style="color: transparent; text-shadow: 0 0 0 #28a745">➕</span>
                Добавить номенклатуру
            </h6>
            <b-collapse id="find-nomenclature">
                <b-row>
                    <b-col md="6">
                        <b-form-group description="Номенклатура">
                            <nomenclature-input :disabled="disabled" v-model="nomenclature"></nomenclature-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group style="margin-bottom: .5rem;">
                            <b-input-group :prepend="measureUnit">
                                <input :disabled="disabled" v-model="count" type="number" class="form-control" />
                                <b-input-group-append>
                                    <b-button
                                        @click="add"
                                        variant="success"
                                        :disabled="disabled || nomenclature === null || !count"
                                    >Добавить</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-collapse>
        </b-card-body>
    </b-card>
</template>

<script>
    import NomenclatureInput from "@components/_common/NomenclatureInput";

    export default {
        name: "FindComponent",
        components: { NomenclatureInput },
        props: {
            addItem: Function,
            disabled: {type: Boolean, default: false}
        },
        computed: {
            measureUnit() {
                return this.nomenclature ? this.nomenclature.measureUnit.name : '';
            }
        },
        data() {
            return {
                count: 0,
                nomenclature: null
            }
        },
        methods: {
            add() {
                this.addItem( {
                    nomenclature: this.nomenclature,
                    count: parseFloat( this.count )
                } );

                this.count = 0;
            }
        }
    }
</script>

<style scoped lang="sass">
    .multiselect__element
        z-index: 9999
</style>

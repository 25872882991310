<template>
    <div class="text-left" id="quality-control-act-nomenclature-list">
        <b-card-group style="display: block;">
            <b-card no-body v-for="item in items" :key="item.id" :bg-variant="item._rowVariant">
                <b-card-body style="padding: 0 .4rem;">
                    <div style="float: right;" @click="rowClicked( item )">
                        <span style="line-height: 24px;" :class="isItemValid( item ) ? 'valid-item' : 'not-valid-item'">
                            {{ item.count }} {{ item.nomenclature.measureUnit.name }}
                        </span>
                    </div>

                    <h6 style="margin-bottom: 0; line-height: 24px;" @click="rowClicked( item )">{{ item.nomenclature.name }}</h6>

                    <b-collapse :id="item.id + ''" accordion="items" v-model="item.collapsed">
                        <b-row style="padding-top: 20px;">
                            <b-col md="12" title="Кол-во">
                                <b-form-group>
                                    <b-input-group>
                                        <template #append>
                                            <b-input-group-text @click="toggleContainerInputs( item )">
                                                {{ item.nomenclature.measureUnit.name }}
                                            </b-input-group-text>
                                        </template>
                                        <input  :disabled="disabled && !userIsAdmin" type="number" v-model="item.count" class="form-control">
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                            <b-col md="12" title="Партия">
                                <b-form-group>
                                    <b-input-group>
                                        <nomenclature-lot-pick
                                            :disabled="disabled && !userIsAdmin"
                                            v-model="item.nomenclatureLot"
                                            :available-items="nomenclatureLots"
                                            :nomenclatureId="item.nomenclature.id"
                                            :optionFormatter="nomenclatureLotOptionFormatter"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="6" style="text-align: left">
                                <b-button variant="info" :disabled="disabled && !userIsAdmin" @click="$emit( 'copy', item )">
                                    <i class="fas fa-clone" style="color: white;"></i>
                                </b-button>
                                <b-button variant="danger" :disabled="disabled && !userIsAdmin" @click="$emit( 'remove', item )" class="ml-2" style="color: white;">
                                    <i class="fas fa-times" style="width: 14px;"></i>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-collapse>
                </b-card-body>
            </b-card>
        </b-card-group>
    </div>
</template>

<script>
    import map from 'lodash/map';

    import moment from 'moment';
    import NomenclatureLotPick from '@components/_common/NomenclatureLotPick';
    import NomenclatureListBase from '@components/_common/NomenclatureListBase';
    import { NOMENCLATURE_LOTS_ENDPOINT } from '@utils/endpoints';
    import {ROLE_ADMIN} from "@utils/Roles";

    const RAW_MATERIALS_NOT_PROPER_QUALITY = "raw_materials_not_proper_quality"

    const WITH = [
        'nomenclature',
        'admissionAct.contractor',
    ];

    export default {
        name: "nomenclature-list",

        extends: NomenclatureListBase,
        components: { NomenclatureLotPick },

        props: {
            act: { type: Object },
            items: { type: Array },
            disabled: {type: Boolean, default: false}
        },

        data() {
            return {
                nomenclatureLots: []
            };
        },

        computed: {
            nomenclatureIds() {
                return map( this.items, function ( item ) {
                    return item.nomenclature.id;
                } );
            },
            actReasonName() {
                if (this.act && this.act.reason && this.act.reason.name) {
                    return this.act.reason.name;
                }
                return null;
            },
            userIsAdmin() {
                return this.$auth.user().roles
                    && this.$auth.user().roles.some((role) => ROLE_ADMIN === role);
            }
        },

        watch: {
            items: {
                deep: true,
                handler() {
                    this.getNomenclatureLots();
                }
            },
        },

        methods: {
            getNomenclatureLots() {
                this.nomenclatureLots = [];

                let params = {
                    filters: {
                        nomenclature_ids: this.nomenclatureIds,
                        limit: 1000
                    },
                    with: WITH,
                    without_loading: true
                };

                this.$http
                    .get(NOMENCLATURE_LOTS_ENDPOINT + '/index', { params })
                    .then(response => response.data.data.data)
                    .then(nomenclatureLots => {
                        this.nomenclatureLots = nomenclatureLots;
                    });
            },

            isItemValid( item ) {
                return item.count && item.nomenclatureLot;
            },

            nomenclatureLotOptionFormatter(nomenclatureLot) {
                if (this.actReasonName !== RAW_MATERIALS_NOT_PROPER_QUALITY) {
                    return nomenclatureLot.name;
                }
                if (nomenclatureLot.admissionAct && nomenclatureLot.admissionAct.contractor) {
                    const contractorName = nomenclatureLot.admissionAct.contractor.name;
                    const incomingDate = moment(nomenclatureLot.admissionAct.incoming_date).format("DD.MM.YYYY");
                    return `${contractorName}; Поставка: ${incomingDate}`;
                }
                const manufactureDate = moment(nomenclatureLot.manufacture_date).format("DD.MM.YYYY");
                return `Изг: ${manufactureDate}`;
            }
        }
    }
</script>

<style scoped lang="sass">
    #quality-control-act-nomenclature-list input
        font-size: small !important

    #quality-control-act-nomenclature-list .card.bg-secondary
        color: white !important

    #quality-control-act-nomenclature-list .card.bg-secondary .text-muted
        color: white !important

    #quality-control-act-nomenclature-list .card-group > .card
        border-radius: 0 !important
        margin-bottom: 0 !important

    #quality-control-act-nomenclature-list .valid-item
        background: #28a745
        border-radius: 5px
        padding: 0 2px
        color: white

    #quality-control-nomenclature-list .not-valid-item
        background: rgba(236, 46, 64, 0.91)
        border-radius: 5px
        padding: 0 2px
        color: white
</style>

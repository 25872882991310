<template>
    <div class="act-form quality-control-act-form">
        <b-alert v-if="request.msg" :variant="variant" dismissible show>{{ request.msg }}</b-alert>

        <b-tabs pills card v-if="act">
            <b-tab active title="Продукция">
                <b-card-header v-b-toggle.accordion1 header-tag="header" class="p-1" role="tab">
                    <b-row class="accordion-button">
                        <b-col md="11" class="text-left">
                            <div v-if="!act.storage && !act.date && !act.shift">Нет данных</div>
                            <div v-else>
                                <template v-if="act.storage">{{ act.storage.name }}</template>
                                <template v-if="act.date || act.shift">(</template>
                                <template v-if="act.date">{{ formattedDate }}</template>
                                <template v-if="act.date && act.shift">/</template>
                                <template v-if="act.shift">{{ act.shift.name }}</template>
                                <template v-if="act.date || act.shift">)</template>
                            </div>
                        </b-col>
                        <b-col md="1" class="text-right"><i class="fas fa-arrow-down"></i></b-col>
                    </b-row>
                </b-card-header>

                <b-card>
                    <b-collapse id="accordion1">
                        <b-row>
                            <b-col md="12">
                                <b-form-group description="Цех/склад">
                                    <storage-picker :disabled="disabled" v-model="act.storage"
                                                    :options=true></storage-picker>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="6">
                                <b-form-group description="Дата">
                                    <input
                                        v-model="act.date"
                                        :disabled="disabled"
                                        placeholder="Дата"
                                        class="form-control"
                                        type="datetime-local"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group description="Смена">
                                    <shift-input :disabled="disabled" v-model="act.shift"></shift-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-collapse>

                    <b-row>
                        <b-col md="12">
                            <b-form-group description="Причина">
                                <quality-control-act-reason-input :disabled="disabled && !userIsAdmin"
                                                                  v-model="act.reason"/>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row v-if="act">
                        <b-col md="12">
                            <h4 class="text-center">Сырье</h4>

                            <nomenclature-list
                                :act="act"
                                :items="act.items"
                                :disabled="disabled"
                                v-model="selectedItem"
                                ref="nomenclatureList"

                                @copy="copyItem"
                                @remove="removeItem"
                            ></nomenclature-list>

                            <find-component :disabled="disabled && !userIsAdmin" :addItem="addItem"
                                            v-if="act"></find-component>
                        </b-col>
                    </b-row>
                </b-card>
            </b-tab>

            <b-tab title="Заверители">
                <b-card>
                    <b-row>
                        <b-col md="12">
                            <table class="table table-striped">
                                <tbody style="font-size:18px;">
                                <tr v-for="witness in act.witnesses">
                                    <td>{{ witness.username }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </b-col>
                    </b-row>

                    <b-row v-if="!isAuthor">
                        <b-col md="12">
                            <b-button
                                variant="danger"
                                style="color:#fff;"
                                :disabled="disabled"
                                v-if="!isCertified"
                                @click="certificationAct()"
                            >Заверить акт
                            </b-button>
                            <b-button
                                v-else
                                variant="danger"
                                style="color:#fff;"
                                :disabled="disabled"
                                @click="certificationAct()"
                            >Отменить заверение
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-tab>

            <b-tab :title="( act.comment ? '⭐ ' : '' ) + `Коммент.`">
                <b-card>
                    <b-row>
                        <b-col md="12">
                            <b-form-textarea :disabled="disabled" v-model="act.comment"
                                             style="min-height:400px;"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-tab>
        </b-tabs>

        <control-panel v-if="act && act.transferAct">
            <b-button
                variant="link"
                :to="{ name: 'MaterialTransferActUpdate', params: { id: act.transferAct.id } }"
            >Передача №{{ act.transferAct.id }}
            </b-button>
        </control-panel>

        <control-panel v-if="act">
            <b-button variant="danger" @click="$router.go( -1 )">Выйти</b-button>

            <b-dropdown v-if="id" variant="primary">
                <template #button-content>
                    <b-icon-printer-fill/>
                </template>
                <b-dropdown-item @click="printDefectLabel">Этикетка брака (PDF)</b-dropdown-item>
            </b-dropdown>

            <b-button
                variant="warning"
                style="color:#fff;"
                @click="transferTo"
                :disabled="disabled"
                v-if="act.id && !act.transferAct"
            >Передать в
            </b-button>

            <b-button variant="success" @click="saveAct" :disabled="disabled && !userIsAdmin" v-if="act.id">Сохранить
            </b-button>
            <b-button variant="success" @click="createAct" :disabled="disabled" v-else>Создать</b-button>
        </control-panel>

        <users-of-the-act :act="act"></users-of-the-act>
    </div>
</template>

<script>
import moment from 'moment';

import map       from 'lodash/map';
import keys      from 'lodash/keys';
import uniq      from 'lodash/uniq';
import keyBy     from 'lodash/keyBy';
import forEach   from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';

import ShiftInput                   from '@components/_common/ShiftInput';
import ControlPanel                 from '@components/_common/ControlPanel';
import UsersOfTheAct                from '@components/_common/UsersOfTheAct';
import StoragePicker                from '@components/_common/StoragePicker';
import QualityControlActReasonInput from './components/QualityControlActReasonInput';

import FindComponent    from './components/FindComponent';
import NomenclatureList from './components/NomenclatureList';

import {NOMENCLATURE_LOTS_ENDPOINT, NOMENCLATURES_ENDPOINT, QUALITY_CONTROL_ACT_ENDPOINT} from "@utils/endpoints";
import {ROLE_ADMIN, ROLE_STOCKMAN}                                                        from '@utils/Roles';
import Downloader                                                                         from "@utils/Downloader";

const HUMANIZED_DATE = 'DD.MM.YYYY';
const FULL_FORMAT_DATE = 'YYYY-MM-DDTHH:mm:ss';

const WITH = [
    'storage',
    'reason',
    'shift',
    'author',
    'witnesses',
    'items.nomenclature.measureUnit',
    'items.nomenclatureLot',
    'transferAct',
];

export default {
    name: "Form",

    components: {
        QualityControlActReasonInput,
        UsersOfTheAct,
        StoragePicker,
        ControlPanel,
        ShiftInput,

        NomenclatureList,
        FindComponent
    },

    props: {
        id: {}
    },

    data() {
        return {
            act: {
                items: [],
                shift: null,
                reason: null,
                storage: null,
                comment: null,
                witnesses: [],
                transferAct: null,
                date: moment().format(FULL_FORMAT_DATE)
            },
            selectedItem: null,

            request: {
                msg: null,
                error: false
            },
        };
    },

    watch: {
        'act.storage'(value, oldValue) {
            if (oldValue && !this.act.id) {
                this.act.items = [];
                this.selectedItem = null;
            }
        },
        '$route': 'getAct'
    },

    computed: {
        disabled() {
            return !!this.act.blocked;
        },
        isAuthor() {
            // return !this.act.id || this.act.author.id === this.$auth.user().id;
            return false;
        },

        isCertified() {
            let index = this.act.witnesses.findIndex(witness => witness.id === this.$auth.user().id);

            return index !== -1;
        },

        params() {
            return this.$route.params;
        },

        actForSave() {
            let preparedAct = cloneDeep(this.act);

            delete preparedAct.items;
            delete preparedAct.shift;
            delete preparedAct.reason;
            delete preparedAct.storage;

            preparedAct.items = [];
            preparedAct.witnesses = [];
            preparedAct.shift_id = this.act.shift ? this.act.shift.id : null;
            preparedAct.reason_id = this.act.reason ? this.act.reason.id : null;
            preparedAct.storage_id = this.act.storage ? this.act.storage.id : null;
            preparedAct.date = moment(preparedAct.date).format('YYYY-MM-DD HH:mm:ss');

            forEach(this.act.items, item => preparedAct.items.push({
                id: !!item.exists ? item.id : null,
                count: parseFloat(item.count),
                nomenclature_id: item.nomenclature.id,
                nomenclature_lot_id: item.nomenclatureLot ? item.nomenclatureLot.id : null
            }));

            preparedAct['with'] = WITH;

            return preparedAct;
        },

        variant() {
            return this.request.error ? 'danger' : 'success';
        },

        formattedDate() {
            return moment(this.act.date).format(HUMANIZED_DATE);
        },

        transferData() {
            let transferData = {
                placeId: this.act.id,
                place: QUALITY_CONTROL_ACT_ENDPOINT,
                storage: this.act.storage,
                transmissionItems: []
            };

            forEach(this.act.items, function (item) {
                transferData.transmissionItems.push({
                    count: item.count,
                    nomenclature: {id: item.nomenclature.id},
                    nomenclatureLot: item.nomenclatureLot ? {id: item.nomenclatureLot.id} : null
                });
            });

            return transferData;
        },
        userIsAdmin() {
            return this.$auth.user().roles
                && this.$auth.user().roles.some((role) => ROLE_ADMIN === role);
        },
        userIsStockman() {
            return this.$auth.user().roles
                && this.$auth.user().roles.some((role) => ROLE_STOCKMAN === role);
        }
    },

    methods: {
        transferTo() {
            let params = {transfer: this.transferData};

            this.$router.push({name: 'MaterialTransferActCreate', params});
        },

        addItem(item, forceNew = false) {
            let index = findIndex(this.act.items, i => {
                return i.nomenclature.id === item.nomenclature.id;
            });

            if (index === -1 || forceNew) {
                item.id = Math.ceil(Math.random() * 10000000);
                item.exists = false;

                this.act.items.push(item);

                this.$refs.nomenclatureList.selectItem(item, true);
            } else {
                this.act.items[index].count =
                    parseFloat((this.act.items[index].count + item.count).toFixed(10));

                this.$refs.nomenclatureList.selectItem(this.act.items[index], true);
            }
        },

        copyItem(item) {
            let newItem = cloneDeep(item);

            delete newItem.id;
            delete newItem['@id'];
            delete newItem.collapsed;
            delete newItem._rowVariant;

            this.addItem(newItem, true);
        },

        removeItem(item) {
            if (window.confirm('Удалить строку?')) {
                let index = this.act.items.findIndex(i => i === item);

                if (index !== -1) {
                    this.act.items.splice(index, 1);
                }
            }
        },

        clearRequest() {
            this.request = {
                msg: null,
                error: false,
            };
        },

        getAct() {
            if (this.id) {
                this.$http
                    .get(QUALITY_CONTROL_ACT_ENDPOINT + '/' + this.id, {
                        params: {
                            with: WITH
                        }
                    })
                    .then(response => {
                        this.act = response.data.data;
                        this.act.date = moment(response.data.data.date).format(FULL_FORMAT_DATE);
                    })
                    .catch(error => {
                        this.request.error = true;
                        this.request.msg = 'Ошибка получения акта: ' + error.data.message;
                    });

                return;
            }

            if (this.params.transfer) {
                this.initTransfer(this.params.transfer);
            }
        },

        initTransfer(params) {
            if (params.date) {
                this.act.date = moment(params.date).format(FULL_FORMAT_DATE);
            }

            if (params.shift) {
                this.act.shift = params.shift;
            }

            if (params.storage) {
                this.act.storage = params.storage;
            }

            if (params.items && params.items.length > 0) {
                let items = params.items;

                let aggregatedByLots = {};
                let nomenclatureWithoutLots = [];

                forEach(items, item => {
                    if (item.nomenclatureLotId) {
                        aggregatedByLots[item.nomenclatureLotId.toString()] = item.count;
                        return;
                    }

                    nomenclatureWithoutLots.push(item);
                });

                let nomenclatureLotsIds = keys(aggregatedByLots);
                let nomenclatureWithoutLotsIds = uniq(map(nomenclatureWithoutLots, 'nomenclatureId'));

                if (nomenclatureLotsIds.length) {
                    this.$http
                        .get(NOMENCLATURE_LOTS_ENDPOINT + '/index', {
                            params: {
                                ids: nomenclatureLotsIds,
                                with: ['nomenclature.measureUnit']
                            }
                        })
                        .then(response => {
                            forEach(response.data.data.data, item => {
                                let count = aggregatedByLots[item.id.toString()];

                                this.copyItem({
                                    nomenclature: item.nomenclature,
                                    count: parseFloat(count),
                                    nomenclatureLot: item
                                });
                            });
                        })
                        .catch(response => this.$root.responseError(response, 'Ошибка во время перемещения'));
                }

                this.$http
                    .get(NOMENCLATURES_ENDPOINT, {params: {ids: nomenclatureWithoutLotsIds, with: ['measureUnit']}})
                    .then(response => {
                        let nomenclaturesHash = keyBy(response.data.data.data, 'id');

                        forEach(nomenclatureWithoutLots, item => {
                            this.copyItem({
                                nomenclature: nomenclaturesHash[item.nomenclatureId],
                                count: item.count,
                                nomenclatureLot: null
                            });
                        });
                    })
                    .catch(response => this.$root.responseError(response, 'Ошибка во время перемещения'));
            }
        },

        saveAct() {
            this.clearRequest();

            this.$http
                .put(
                    QUALITY_CONTROL_ACT_ENDPOINT + `/${this.act.id}`, this.actForSave
                )
                .then(() => this.request.msg = 'Акт успешно сохранен')
                .catch(error => {
                    if (!!error.data.errors) {
                        Object.keys(error.data.errors).map(k => {
                            if (Array.isArray(error.data.errors[k])) {
                                error.data.errors[k].map(e => {
                                    this.$toast.error(e);
                                })
                            }
                        })
                    } else {
                        this.request.msg = 'Ошибка сохранения акта: ' + error.data.message;
                    }

                    this.request.error = true;
                });
        },

        createAct() {
            this.clearRequest();

            this.$http
                .post(QUALITY_CONTROL_ACT_ENDPOINT, this.actForSave)
                .then(response => {
                    this.act = response.data;
                    this.$router.push({name: 'QualityControlActUpdate', params: {id: response.data.id}})
                })
                .catch(error => {
                    this.request.error = true;

                    if (!!error.data.errors) {
                        Object.keys(error.data.errors).map(k => {
                            if (Array.isArray(error.data.errors[k])) {
                                error.data.errors[k].map(e => {
                                    this.$toast.error(e);
                                })
                            }
                        })
                    } else {
                        this.request.msg = 'Ошибка создания акта: ' + error.data.message;
                    }
                });
        },

        certificationAct() {
            this.$http.put(QUALITY_CONTROL_ACT_ENDPOINT + `/${this.act.id}/review`)
                .then(response => {
                    let index = findIndex(this.act.witnesses, w => w.id === this.$auth.user().id);
                    if (index !== -1) {
                        this.act.witnesses.splice(index, 1);
                    } else {
                        this.act.witnesses.push(this.$auth.user());
                    }
                    this.$forceUpdate();
                });
        },

        printDefectLabel() {
            this.$http.get(QUALITY_CONTROL_ACT_ENDPOINT + `/${this.act.id}/defect-label`, {responseType: 'arraybuffer'})
                .then((response) => {
                    Downloader.download(
                        response.data,
                        'application/pdf',
                        `Этикетка брака ${moment(this.act.date).format('DD.MM.YYYY')}.pdf`
                    );
                });
        }
    },

    created() {
        this.getAct();
    }
}
</script>

<style scoped lang="sass">
.act-form
    height: 100%
    padding-bottom: 50px

.quality-control-act-form input,
.quality-control-act-form select
    font-size: 14px !important

.accordion-button
    padding: 10px
    font-size: 20px
    font-weight: bold
</style>
